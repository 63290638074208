export default {
  "accounts": "qNu",
  "header-subtitle": "qvS title-4",
  "subtitle-container": "qvc",
  "subtitle": "qvq title-3 mb-16",
  "amount": "qvZ body-2",
  "list--cashbeeSavings": "qvR",
  "connections-banner": "qvQ",
  "list": "qve",
  "icon": "qvB",
  "badge": "qvE",
  "discover-tile": "qvd"
};
