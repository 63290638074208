export default {
  "member-details": "qYk",
  "header": "qYJ",
  "header-img": "qYs",
  "invited-persona": "qYF",
  "revoked-persona": "qYg",
  "invited-icon": "qYD",
  "revoked-icon": "qYV",
  "dropdown": "qYL",
  "header-body": "qYT",
  "name": "qYz title-3",
  "email": "qYa caption-bold",
  "actions": "qYH body-2",
  "actions-cards": "qYO",
  "actions-transactions": "qYm",
  "body": "qYp",
  "expense-permissions-section": "qYx",
  "body-title": "qYw title-4",
  "body-attr": "qYN",
  "body-label": "qYv",
  "attr-buttons": "qYo",
  "body-role": "qYn small",
  "details-actions": "qYi",
  "actions-item": "qYA",
  "disclaimer": "qYY",
  "permissions": "qYh",
  "permission": "qYf",
  "permission-label": "qYK",
  "permission-check": "qYG",
  "permission-missing": "qYr",
  "editable-section": "qYb"
};
