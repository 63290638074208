export default {
  "mt-16": "qjX",
  "title": "qjC",
  "avatar": "qjk",
  "edit-organization-form": "qjJ",
  "dropdown-content": "qjs",
  "city-block": "qjF",
  "zip-code-input": "qjg",
  "cancel-button": "qjD",
  "error-message": "qjV",
  "nature-of-operations": "qjL",
  "error": "qjT",
  "legal-code": "qjz",
  "activity-type": "qja"
};
