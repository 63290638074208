export default {
  "dropdown-icon": "Zqa",
  "dropdown": "ZqH",
  "download-button": "ZqO",
  "connections-banner": "Zqm",
  "recommendation-cards": "Zqp",
  "wrapper-with-cards": "Zqx",
  "transactions-wrapper": "Zqw",
  "separator": "ZqN",
  "with-transaction": "Zqv",
  "annual-billing-banner": "Zqo",
  "mt-132": "Zqn",
  "transactions-sidebar": "Zqi",
  "empty-state-wrapper": "ZqA"
};
