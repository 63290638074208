export default {
  "wrapper": "quC",
  "integrations": "quk",
  "description": "quJ body-2",
  "api": "qus flex flex-column",
  "api-container": "quF flex large",
  "api-input": "qug large",
  "api-btn": "quD",
  "key-input": "quV"
};
