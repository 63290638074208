export default {
  "header": "qjH",
  "content": "qjO",
  "read-only": "qjm",
  "document-collection-banner": "qjp",
  "submit-banner": "qjx",
  "review-banner": "qjw",
  "disclaimer": "qjN",
  "title": "qjv",
  "card-container": "qjo",
  "representatives-section": "qjn",
  "disabled-href": "qji"
};
