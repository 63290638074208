export default {
  "container": "qrQ",
  "content": "qre",
  "logos": "qrB",
  "logo": "qrE",
  "animation": "qrd",
  "title": "qrI title-2 mb-32",
  "avatar": "qrt",
  "actions": "qrM mb-32",
  "footer": "qrP body-2"
};
