export default {
  "wrapper": "qKe",
  "header": "qKB",
  "main": "qKE",
  "frequency": "qKd body-1",
  "radiogroup": "qKI",
  "divider": "qKt",
  "option-wrapper": "qKM",
  "option-details-wrapper": "qKP",
  "option-label": "qKl",
  "option-name": "qKX",
  "option-details-text": "qKC",
  "spinner-wrapper": "qKk",
  "spinner": "qKJ"
};
