export default {
  "guests": "qYy",
  "guests--upsell-screen": "qYU",
  "no-members": "qYj",
  "teams-header": "qYW",
  "empty": "qYu",
  "invite-text": "qhS",
  "body": "qhc",
  "members": "qhq",
  "members-list": "qhZ",
  "members-title": "qhR caption-bold",
  "member": "qhQ",
  "details": "qhe"
};
