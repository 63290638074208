export default {
  "multi-transfer": "Zqb",
  "container": "Zqy",
  "title": "ZqU",
  "subtitle": "Zqj",
  "form": "ZqW",
  "form-title": "Zqu",
  "files-buttons": "ZZS",
  "files-buttons-item": "ZZc",
  "selected": "ZZq"
};
