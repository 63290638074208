export default {
  "container": "qWz",
  "wrapper": "qWa",
  "grey-header-block": "qWH",
  "connect-app-header": "qWO",
  "connect-app-header-link": "qWm",
  "connect-app-header-content": "qWp",
  "connect-app-header-content-left": "qWx",
  "application-avatar": "qWw",
  "connect-app-header-cta": "qWN",
  "connect-app-header-meta": "qWv",
  "connect-app-content": "qWo",
  "connect-app-description": "qWn",
  "title-wrapper": "qWi",
  "title": "qWA title-1",
  "tag-line": "qWY body-2",
  "connect-app-body": "qWh"
};
