export default {
  "bank-accounts": "qUj l-app-content__wrapper",
  "header-wrapper": "qUW",
  "scrolled-top": "qUu",
  "header": "qjS l-app-content__page-header",
  "create-account-button": "qjc btn btn--primary",
  "main": "qjq",
  "accounts": "qjZ",
  "subtitle-container": "qjR",
  "subtitle": "qjQ title-3 mb-16",
  "amount": "qje body-2",
  "list--cashbeeSavings": "qjB"
};
