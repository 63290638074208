export default {
  "details": "qKp",
  "header": "qKx",
  "avatar": "qKw mr-16",
  "header-beneficiary": "qKN",
  "header-beneficiary-activity": "qKv",
  "header-beneficiary-details": "qKo",
  "header-beneficiary-details-recurring": "qKn",
  "infos": "qKi",
  "infos-title": "qKA",
  "infos-empty": "qKY",
  "infos-list": "qKh",
  "infos-list-item": "qKf",
  "infos-list-item-left": "qKK",
  "infos-list-item-right": "qKG",
  "infos-list-item-attachment": "qKr",
  "infos-transactions": "qKb",
  "actions": "qKy"
};
