export default {
  "signin": "ZcC",
  "form": "Zck",
  "illustration": "ZcJ",
  "purple": "Zcs",
  "mint": "ZcF",
  "mustard": "Zcg",
  "peach": "ZcD",
  "form-wrapper": "ZcV",
  "header-logo": "ZcL",
  "links": "ZcT",
  "no-account-label": "Zcz",
  "spinner-container": "Zca",
  "main-image-container": "ZcH",
  "main-image": "ZcO"
};
