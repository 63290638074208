export default {
  "container": "qrJ",
  "content": "qrs",
  "logos": "qrF",
  "logo": "qrg",
  "animation": "qrD",
  "title": "qrV title-2 mb-32",
  "avatar": "qrL",
  "dropdown": "qrT",
  "disabled": "qrz",
  "organization": "qra",
  "actions": "qrH"
};
