export default {
  "header": "qNP",
  "btn-preset": "qNl btn btn-filter mr-8 mb-8",
  "invoices-wrapper": "qNX",
  "statements-table": "qNC",
  "no-result": "qNk",
  "lottie-illustration": "qNJ",
  "wrapper": "qNs",
  "access-denied": "qNF",
  "access-denied-illustration": "qNg",
  "application-card": "qND",
  "presets-wrapper": "qNV",
  "error-wrapper": "qNL",
  "error-container": "qNT",
  "error-illustration": "qNz mb-32",
  "invoicing-info": "qNa"
};
