export default {
  "container": "ZSY",
  "header-cell": "ZSh caption-bold",
  "col-8": "ZSf",
  "table": "ZSK",
  "cell": "ZSG body-2",
  "cell-content": "ZSr",
  "row": "ZSb",
  "empty": "ZSy",
  "quick-actions": "ZSU",
  "card-infos": "ZSj",
  "card-digits": "ZSW",
  "empty-state": "ZSu"
};
