export default {
  "page-wrapper": "Zcm",
  "content-wrapper": "Zcp",
  "content": "Zcx",
  "period-field": "Zcw",
  "format-options": "ZcN",
  "format-option": "Zcv",
  "format-radio": "Zco",
  "format-description": "Zcn body-2"
};
