export default {
  "container": "qWd",
  "link-grey": "qWI",
  "slack-section-title": "qWt title-4 mb-8",
  "edit-channel-text": "qWM body-2 mb-16",
  "rule-section-title": "qWP title-4 mb-16",
  "rule-cards-container": "qWl",
  "edit-slack-container": "qWX",
  "rule-card": "qWC",
  "add-rule-container": "qWk",
  "add-rule-link-button": "qWJ",
  "add-rule-text": "qWs",
  "empty-state": "qWF",
  "empty-state-textblock": "qWg",
  "empty-state-title": "qWD title-2",
  "empty-state-description": "qWV body-2",
  "empty-state-illustration": "qWL",
  "rules": "qWT"
};
