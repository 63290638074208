export default {
  "container": "qWr",
  "content": "qWb",
  "setup-box": "qWy",
  "setup-wizard": "qWU",
  "setup-success": "qWj",
  "setup-success-lottie": "qWW",
  "setup-close-btn": "qWu",
  "tray-connection": "quS",
  "close-button": "quc"
};
