export default {
  "container": "qyT",
  "form-container": "qyz",
  "preview-container": "qya",
  "pdf-preview-container": "qyH",
  "content": "qyO",
  "title": "qym",
  "tabs-container": "qyp",
  "close-button": "qyx btn btn--icon-only btn--tertiary btn--large",
  "pdf-preview": "qyw",
  "header": "qyN",
  "without-tabs": "qyv"
};
