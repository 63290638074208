export default {
  "mandates": "qKU",
  "mandates-empty": "qKj",
  "header": "qKW",
  "header-empty": "qKu",
  "header-content": "qGS",
  "search": "qGc",
  "search-bar": "qGq",
  "search-spinner": "qGZ",
  "body": "qGR",
  "isEmpty": "qGQ",
  "left-section": "qGe",
  "mandates-list": "qGB",
  "mandate-suspended": "qGE",
  "list-title": "qGd caption-bold",
  "list-empty": "qGI",
  "empty-illustration": "qGt",
  "empty-title": "qGM title-3",
  "empty-description": "qGP body-2",
  "body-details": "qGl",
  "l-app-content__page-header": "qGX",
  "pagination-footer": "qGC"
};
