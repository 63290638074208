export default {
  "container": "qUE",
  "form-container": "qUd",
  "title": "qUI",
  "title-border": "qUt",
  "tabs-container": "qUM",
  "preview-container": "qUP",
  "email-preview-container": "qUl",
  "close-button": "qUX btn btn--icon-only btn--tertiary btn--large"
};
