export default {
  "team": "qGO",
  "no-members": "qGm",
  "teams-header": "qGp",
  "header-search": "qGx",
  "search-bar": "qGw",
  "invite": "qGN body-2",
  "invite-text": "qGv",
  "empty-illustration": "qGo",
  "empty-title": "qGn title-3",
  "empty-description": "qGi body-2",
  "pagination-footer": "qGA",
  "filter-search": "qGY",
  "fullsize": "qGh",
  "tabs-container": "qGf",
  "tabs-separator": "qGK",
  "tooltip-wrapper": "qGG",
  "invite-cta": "qGr"
};
