export default {
  "page-container": "qfp",
  "form": "qfx",
  "close-button": "qfw btn btn--icon-only btn--tertiary btn--large",
  "preview": "qfN",
  "preview-padding": "qfv",
  "title": "qfo",
  "header": "qfn",
  "without-tabs": "qfi",
  "form-section": "qfA",
  "form-footer": "qfY",
  "disclaimer-text": "qfh"
};
