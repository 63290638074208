export default {
  "page-header": "ZZk",
  "header-breadcrumb": "ZZJ",
  "tabs-container": "ZZs",
  "bottom-border": "ZZF",
  "tabs-separator": "ZZg",
  "previous-request": "ZZD",
  "body-link": "ZZV",
  "border-top": "ZZL"
};
