export default {
  "page-container": "qfy",
  "form": "qfU",
  "close-button": "qfj btn btn--icon-only btn--tertiary btn--large",
  "preview": "qfW",
  "preview-padding": "qfu",
  "title": "qKS",
  "header": "qKc",
  "without-tabs": "qKq",
  "form-section": "qKZ",
  "form-footer": "qKR",
  "disclaimer-text": "qKQ"
};
