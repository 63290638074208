export default {
  "supplier-invoices-page-header": "Zci",
  "filters-container": "ZcA",
  "filters": "ZcY",
  "bulk-actions": "Zch",
  "item-count": "Zcf",
  "higher-index": "ZcK",
  "title-wrapper": "ZcG",
  "title": "Zcr",
  "subtitle": "Zcb",
  "table-container": "Zcy",
  "file-dropzone": "ZcU",
  "dropzone-visible": "Zcj",
  "header-actions": "ZcW",
  "header-wrapper": "Zcu"
};
