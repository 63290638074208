export default {
  "title": "quu title-3",
  "personal-profile-container": "ZSS",
  "personal-profile-section": "ZSc",
  "member-info-title": "ZSq",
  "kyc-complete-profile": "ZSZ",
  "info-fields": "ZSR",
  "field": "ZSQ",
  "address-fields": "ZSe",
  "field-span": "ZSB",
  "input": "ZSE",
  "cancel": "ZSd"
};
