export default {
  "container": "qjr",
  "wrapper": "qjb",
  "grey-header-block": "qjy",
  "connect-app-header": "qjU",
  "connect-app-header-link": "qjj",
  "connect-app-header-content": "qjW",
  "connect-app-header-content-default": "qju",
  "connect-app-header-content-left": "qWS",
  "button-block": "qWc",
  "connect-app-body": "qWq",
  "connect-app-body-left-content": "qWZ",
  "connect-app-body-sidebar": "qWR"
};
