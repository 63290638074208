export default {
  "body": "qGk",
  "members": "qGJ",
  "members-results": "qGs",
  "members-list": "qGF",
  "members-title": "qGg caption-bold",
  "member": "qGD",
  "empty-illustration": "qGV",
  "empty-title": "qGL title-3",
  "empty-description": "qGT body-2",
  "invitable-members": "qGz",
  "details": "qGa",
  "pagination-footer": "qGH"
};
