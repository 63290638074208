export default {
  "page-wrapper": "qNH",
  "container": "qNO",
  "sidebar": "qNm",
  "search-input": "qNp",
  "organizations-list": "qNx",
  "content": "qNw",
  "no-result": "qNN",
  "lottie-illustration": "qNv",
  "accounting-header": "qNo",
  "logo": "qNn",
  "accounting-title": "qNi"
};
