export default {
  "container": "qyP",
  "form-container": "qyl",
  "preview-container": "qyX",
  "pdf-preview-container": "qyC",
  "content": "qyk",
  "title": "qyJ",
  "tabs-container": "qys",
  "close-button": "qyF btn btn--icon-only btn--tertiary btn--large",
  "pdf-preview": "qyg",
  "header": "qyD",
  "without-tabs": "qyV"
};
