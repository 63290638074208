export default {
  "container": "qyj",
  "form-container": "qyW",
  "preview-container": "qyu",
  "pdf-preview-container": "qUS",
  "content": "qUc",
  "title": "qUq",
  "tabs-container": "qUZ",
  "close-button": "qUR btn btn--icon-only btn--tertiary btn--large",
  "pdf-preview": "qUQ",
  "header": "qUe",
  "without-tabs": "qUB"
};
