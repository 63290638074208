export default {
  "page-wrapper": "qnE",
  "back-button": "qnd",
  "abort-button": "qnI",
  "container": "qnt",
  "subtitle": "qnM",
  "button": "qnP",
  "panel-list": "qnl",
  "panel-list-item": "qnX",
  "card-illustration": "qnC"
};
