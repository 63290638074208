export default {
  "page-wrapper": "qyo",
  "content-wrapper": "qyn",
  "content": "qyi",
  "period-field": "qyA",
  "format-options": "qyY",
  "format-option": "qyh",
  "format-radio": "qyf",
  "format-description": "qyK body-2"
};
