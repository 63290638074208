export default {
  "container": "qbA",
  "form-container": "qbY",
  "title": "qbh",
  "title-border": "qbf",
  "tabs-container": "qbK",
  "preview-container": "qbG",
  "email-preview-container": "qbr",
  "close-button": "qbb btn btn--icon-only btn--tertiary btn--large"
};
