export default {
  "container": "qbU",
  "form-container": "qbj",
  "preview-container": "qbW",
  "credit-note-preview-container": "qbu",
  "credit-note-preview": "qyS",
  "content": "qyc",
  "title": "qyq",
  "tabs-container": "qyZ",
  "close-button": "qyR btn btn--icon-only btn--tertiary btn--large",
  "header": "qyQ",
  "without-tabs": "qye"
};
