export default {
  "splash-landing": "qom",
  "container": "qop",
  "options": "qox",
  "physical": "qow",
  "card-label": "qoN",
  "physical-cta-container": "qov",
  "physical-cta": "qoo",
  "coming-soon": "qon"
};
