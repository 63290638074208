export default {
  "container": "ZSt",
  "security-method": "ZSM",
  "icon": "ZSP",
  "body": "ZSl",
  "status": "ZSX body-2",
  "device": "ZSC",
  "device-icon": "ZSk",
  "subtitle": "ZSJ body-2",
  "current": "ZSs"
};
