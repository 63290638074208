export default {
  "container": "qAv",
  "page-wrapper": "qAo",
  "header": "qAn",
  "back-button-wrapper": "qAi",
  "header-inner": "qAA",
  "header-main-wrapper": "qAY",
  "header-main": "qAh",
  "header-right": "qAf",
  "content-wrapper": "qAK",
  "content": "qAG",
  "content-body": "qAr",
  "sidebar": "qAb"
};
