export default {
  "container": "qhK",
  "compact-header": "qhG",
  "visible": "qhr",
  "page-container": "qhb",
  "main": "qhy",
  "tabs": "qhU",
  "tabs-nav": "qhj",
  "tabs-panels": "qhW",
  "tabs-panel": "qhu body-2",
  "sidebar": "qfS",
  "prismic-content": "qfc"
};
